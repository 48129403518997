import fetch from "cross-fetch";
import AppSettings from "../config";
import queryString from "query-string";
import XMLParser from "react-xml-parser";

export const UPLOAD_LOADING = "NEW_UPLOAD_LOADING";
export const UPLOAD_LOADED = "NEW_UPLOAD_LOADED";

function loadingUpload() {
  return {
    type: UPLOAD_LOADING,
  };
}

function loadedUpload(id: string, json: any) {
  return {
    type: UPLOAD_LOADED,
    uploadId: id,
    payload: json,
  };
}

function newUpload({ site, file, folder }: { site: string; file: any; folder?: string }) {
  const options = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const query = queryString.stringify({
    site,
    folder,
    filename: file.name,
    mimetype: file.type,
  });
  return fetch(`${AppSettings.DIRECT_UPLOAD_API_URL}/new?${query}`, options).then((response) => response.json());
}

function performUpload(json: any, file: any) {
  const form = new FormData();
  for (const key of Object.keys(json.fields)) {
    form.append(key, json.fields[key]);
  }
  form.append("file", file);
  const options = {
    method: "POST",
    body: form,
  };
  return fetch(`${json.url}`, options)
    .then((response) => response.text())
    .then((text) => new XMLParser().parseFromString(text));
}

export function upload({ id, site, folder, file }: { id: string; site: string; file: any; folder?: string }) {
  return (dispatch: any) => {
    dispatch(loadingUpload());
    return newUpload({ site, folder, file })
      .then((json) => performUpload(json, file))
      .then((json) => dispatch(loadedUpload(id, json)));
  };
}
